export default class MatrixField {
    // Variables
    matrix;
    newBlockIndex;
    minBlocks;
    maxBlocks;
    addBlockControllers = {};
    deleteBlockControllers = {};
    collapseBlockControllers = {};
    expandBlockControllers = {};

    constructor(matrix = null) {
        // Make sure there's a matrix element defined
        if (!matrix) {
            return;
        }

        this.matrix = matrix;

        this.newBlockIndex = parseInt(this.matrix.dataset.newBlockIndex, 10);
        this.minBlocks = parseInt(this.matrix.dataset.minBlocks, 10);
        this.maxBlocks = parseInt(this.matrix.dataset.maxBlocks, 10);

        if (!this.newBlockIndex) {
            this.newBlockIndex = this.matrix.children ? (this.matrix.children.length + 1) : 1;
            this.matrix.dataset.newBlockIndex = this.newBlockIndex;
        }

        // Add event listeners
        let addBlockButtons = this.matrix.parentNode.querySelectorAll('[data-add-block]');
        if (addBlockButtons) {
            Array.prototype.forEach.call(addBlockButtons, function(item) {
                this.addBlockControllers[item.id] = new AbortController();
                item.addEventListener('click', this.addBlock.bind(this), {signal: this.addBlockControllers[item.id].signal});
            }, this);
        }

        let blockDeleteButtons = this.matrix.querySelectorAll('[data-delete-block]');
        if (blockDeleteButtons) {
            Array.prototype.forEach.call(blockDeleteButtons, function(item) {
                let id = item.closest('[data-matrix-block]').id;
                this.deleteBlockControllers[id] = new AbortController();
                item.addEventListener('click', this.deleteBlock.bind(this), {signal: this.deleteBlockControllers[id].signal});
            }, this);
        }

        let collapseButtons = this.matrix.querySelectorAll('[data-collapse]');
        if (collapseButtons) {
            Array.prototype.forEach.call(collapseButtons, function(item) {
                let id = item.closest('[data-matrix-block]').id;
                this.collapseBlockControllers[id] = new AbortController();
                item.addEventListener('click', this.collapseBlock, {signal: this.collapseBlockControllers[id].signal});
            }, this);
        }

        let expandButtons = this.matrix.querySelectorAll('[data-expand]');
        if (expandButtons) {
            Array.prototype.forEach.call(expandButtons, function(item) {
                let id = item.closest('[data-matrix-block]').id;
                this.expandBlockControllers[id] = new AbortController();
                item.addEventListener('click', this.expandBlock, {signal: this.expandBlockControllers[id].signal});
            }, this);
        }
    }

    // Methods
    collapseBlock(ev) {
        let block = ev.target.closest('[data-collapsible-item]');
        let collapseButton = block.querySelector('[data-collapse]');
        let expandButton = block.querySelector('[data-expand]');
        let blockPreview = block.querySelector('[data-collapsible-item-preview]');
        let collapsibleContent = block.querySelector('[data-collapsible]');

        let textInputs = block.querySelectorAll('.redactor-in, input[type="text"], input[type="url"]');

        if (textInputs.length) {
            Array.prototype.forEach.call(textInputs, function(textInput, index) {
                if (index === 0) {
                    if (textInput.classList.contains('redactor-in')) {
                        blockPreview.innerText = textInput.textContent.replace(/(\r\n|\n|\r)/gm, "");
                    } else if (textInput.matches('input[type="text"]') || textInput.matches('input[type="url"]')) {
                        blockPreview.innerText = textInput.value.replace(/(\r\n|\n|\r)/gm, "");
                    }
                }
            });
        }

        collapsibleContent.classList.add('hidden');

        collapseButton.classList.add('hidden');
        expandButton.classList.remove('hidden');
    }

    expandBlock(ev) {
        let block = ev.target.closest('[data-collapsible-item]');
        let collapseButton = block.querySelector('[data-collapse]');
        let expandButton = block.querySelector('[data-expand]');
        let blockPreview = block.querySelector('[data-collapsible-item-preview]');
        let collapsibleContent = block.querySelector('[data-collapsible]');

        blockPreview.innerText = '';

        collapsibleContent.classList.remove('hidden');

        collapseButton.classList.remove('hidden');
        expandButton.classList.add('hidden');
    }

    addBlock(ev) {
        if (this.matrix.children.length && this.matrix.children.length === this.maxBlocks) {
            alert(`Can’t add block, this field can have no more than ${this.maxBlocks} blocks.`);
            return;
        }

        let newBlockHTML = ev.target.closest('[data-add-block]').dataset.addBlock;
        let newIndex = 'new' + this.newBlockIndex.toString();

        newBlockHTML = newBlockHTML.replace(/BLOCKID/g, newIndex);

        if (newBlockHTML) {
            let range = document.createRange();
            range.selectNode(this.matrix);
            let newBlock = range.createContextualFragment(newBlockHTML);

            this.matrix.appendChild(newBlock.querySelector('[data-matrix-block]'));
            this.matrix.dataset.newBlockIndex = this.newBlockIndex + 1;

            // this.matrix.querySelector('[data-matrix-block]:last-child [data-delete-block]').addEventListener('click', this.deleteBlock);
            let textInputs = this.matrix.querySelectorAll('[data-matrix-block]:last-child textarea, [data-matrix-block]:last-child input[type="text"], [data-matrix-block]:last-child input[type="url"]');
            if (textInputs.length) {
                Array.prototype.forEach.call(textInputs, function(textInput, index) {
                    if (index === 0) {
                        textInput.focus();
                    }
                });
            }

            let event = new Event(
                'oscar.updateMatrixFields',
                {
                    bubbles: true,
                    cancelable: true
                }
            );

            this.matrix.dispatchEvent(event);
        }
    }

    deleteBlock(ev) {
        if (this.minBlocks && this.matrix.children.length === this.minBlocks) {
            alert(`Can’t delete block, this field must have at least ${this.minBlocks} blocks.`);
            return;
        }

        let confirmDeleteBlock = confirm('Are you sure you want to delete this block? (You cannot undo this action.)');

        if (confirmDeleteBlock) {
            let block = ev.target.closest('[data-matrix-block]');
            block.parentNode.removeChild(block);

            let event = new Event(
                'oscar.updateMatrixFields',
                {
                    bubbles: true,
                    cancelable: true
                }
            );

            this.matrix.dispatchEvent(event);
        }
    }

    /**
     * Destroy the current initialization
     */
    destroy() {
        // Make sure plugin has been initialized
        if (!this.matrix) {
            return;
        }

        // Remove all event listeners
        let addBlockButtons = this.matrix.parentNode.querySelectorAll('[data-add-block]');
        if (addBlockButtons) {
            Array.prototype.forEach.call(addBlockButtons, function(item) {
                if (item.id in this.addBlockControllers) {
                    this.addBlockControllers[item.id].abort();
                }
                // item.removeEventListener('click', this.addBlock);
            }, this);
        }

        let blockDeleteButtons = this.matrix.querySelectorAll('[data-delete-block]');
        if (blockDeleteButtons) {
            Array.prototype.forEach.call(blockDeleteButtons, function(item) {
                let id = item.closest('[data-matrix-block]').id;
                if (id in this.deleteBlockControllers) {
                    this.deleteBlockControllers[id].abort();
                }
                // item.removeEventListener('click', this.deleteBlock);
            }, this);
        }

        let collapseButtons = this.matrix.querySelectorAll('[data-collapse]');
        if (collapseButtons) {
            Array.prototype.forEach.call(collapseButtons, function(item) {
                let id = item.closest('[data-matrix-block]').id;
                if (id in this.collapseBlockControllers) {
                    this.collapseBlockControllers[id].abort();
                }
                // item.removeEventListener('click', this.collapseBlock);
            }, this);
        }

        let expandButtons = this.matrix.querySelectorAll('[data-expand]');
        if (expandButtons) {
            Array.prototype.forEach.call(expandButtons, function(item) {
                let id = item.closest('[data-matrix-block]').id;
                if (id in this.expandBlockControllers) {
                    this.expandBlockControllers[id].abort();
                }
                // item.removeEventListener('click', this.expandBlock);
            }, this);
        }

        // Reset variables
        this.matrix = null;
    }
}

